import {
  Input as antInput,
  Form,
  InputNumber as antInputNumber,
  Checkbox as antCheckBox,
  Select as antSelect,
  TreeSelect as antTreeSelect,
  DatePicker as antDatePicker,
  TimePicker as antTimePicker,
  Dropdown as AntDropDown
} from 'antd';
import styled, { css } from 'styled-components';

import { metrics } from 'assets/styles/commonStyle';
import { colors } from 'assets/styles/commonStyle';

const antTextArea = antInput.TextArea;
const antFormItem = Form.Item;

export const Dropdown = styled(AntDropDown)`
  .anticon-down {
    font-size: 0 !important;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #777;
    font-size: 0;
  }
`;

export const InputWrapper = styled.section<{ margin?: boolean; center?: boolean }>`
  display: flex;
  margin: ${props => (props.margin ? metrics.margin.large : 0)}px 0;
  align-items: ${props => (props.center ? 'center' : 'inherit')};
`;

export const InputLimitWidth = styled.div<{
  width?: string | number;
  margin?: string | number;
  noLeftMargin?: boolean;
}>`
  min-width: ${props => props.width || 200}px;
  margin: 0 ${props => props.margin || metrics.margin.base}px;
  margin-left: ${props => (props.noLeftMargin ? 0 : 'inherit')};
  .ant-select-selection {
    min-width: 50px !important;
  }
`;

interface LimitWidthProps {
  width?: number | string;
  margin?: string | number;
  noLeftMargin?: boolean;
}

export const LimitWidth = styled.div<LimitWidthProps>`
  width: ${props =>
    props.width !== undefined
      ? isNaN(props.width as number)
        ? props.width
        : `${props.width}px`
      : '200px'} !important;
  margin: 0 ${props => (props.margin ? props.margin : metrics.margin.base)}px;
  margin-left: ${props => (props.noLeftMargin ? 0 : 'inherit')};
`;

export const InputLabelHolder = styled.section<{ width?: string; align?: string }>`
  width: ${props => props.width || '33.3%'};
  color: #000000;
  font-weight: 500;
  padding-right: 10px;
  display: flex;
  justify-content: ${props => (props.align === 'left' ? 'flex-start' : 'flex-end')};
  align-items: flex-start;
  text-align: right;
`;

interface InputContentHolderProps {
  marginEqual?: boolean;
  fullwidth?: boolean;
  isFlexFlowRow?: boolean;
  hasSpaces?: boolean;
}

export const InputContentHolder = styled.section<InputContentHolderProps>`
  display: flex;
  flex: 1;
  align-items: center;
  word-break: break-all;
  :nth-child(n) {
    input {
      margin-left: ${props => (props.marginEqual ? metrics.margin.base : 0)}px;
    }
  }
  div {
    width: ${props => (props.fullwidth ? '100%' : '')};
  }
  position: relative;
  ${({ isFlexFlowRow }) =>
    isFlexFlowRow &&
    css`
      & > div {
        flex-flow: row !important;
      }
      .datePicker,
      .timePicker {
        flex: 0 0 150px !important;
      }
    `}
  ${({ hasSpaces }) =>
    hasSpaces &&
    css`
      & > * {
        margin-right: 10px;
      }
    `}
`;

const defaultInput = css`
  .ant-input,
  .ant-input-number {
  }
  &.ant-input,
  .ant-input-number-input {
  }
  &.ant-input-number {
    .ant-input-number-handler-wrap {
      display: none !important;
    }
  }
  &.ant-input-number.ant-input-number-focused {
    box-shadow: none !important;
  }
`;

const defaultTextArea = css`
  &.ant-input {
  }
`;

export const DatePicker = styled(antDatePicker)`
  & .ant-input {
    border: solid 1px ${colors.colorGrayLine} !important;
  }
  & .ant-calendar-picker-icon:after {
    color: #333;
  }
`;

export const TimePicker = styled(antTimePicker)`
  & .ant-time-picker-input {
    border: solid 1px ${colors.colorGrayLine} !important;
  }
  & .ant-time-picker-icon:after {
    color: #333;
  }
`;

export const Checkbox = styled(antCheckBox)``;

export const CheckboxGroup = styled(antCheckBox.Group)`
  &.has-rows {
    display: flex;
    & > .ant-checkbox-group-item {
      display: block;
      padding: 9px 0;
      & ~ .ant-checkbox-group-item {
        padding-top: 0;
      }
    }
  }
  &.is-reversed-style {
    & > .ant-checkbox-group-item {
      & > span {
        &:last-child {
          transition: color 0.3s ease;
        }
      }
      &.ant-checkbox-wrapper-checked {
        & > span {
          &:last-child {
            color: #c3c3c3;
          }
        }
      }
    }
    &.report-style {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      & > * {
        display: flex;
      }
    }
    .ant-checkbox {
      .ant-checkbox-inner {
        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          left: 50%;
          top: 50%;
          opacity: 0;
          width: 1px;
          height: 1px;
          transform: translate(-50%, -50%) rotate(135deg);
          transition: all 0.3s ease;
        }
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: ${colors.colorRed};
          border-color: ${colors.colorRed};
          &::before,
          &::after {
            opacity: 1;
            width: 9px;
            height: 2px;
            border: none;
            background: ${colors.white};
            transition: all 0.2s ease 0.1s;
          }
          &::before {
            transform: translate(-50%, -50%) rotate(135deg);
          }
          &::after {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
`;

export const Select = styled(antSelect)`
  &.ant-select {
    width: 100%;
  }
  &&& {
    .ant-select-selection {
      &__rendered {
        margin-right: 30px;
      }
    }
  }
`;

export const TreeSelect = styled(antTreeSelect)<{ width?: string }>`
  width: ${props => props.width || 'auto'};
  .ant-select-selection {
    border: solid 1px ${colors.colorGrayLine} !important;
    padding: 3px !important;
    min-height: 38px;
    max-height: none;
  }
`;

export const FakeUploadButton = styled.span<{ padding?: string }>`
  color: #fff;
  padding: ${props => props.padding || '10px 15px'};
  background: #2655ee;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
`;

export const Line = styled.div<{ width?: string; top?: string }>`
  border-bottom: ${props => props.width || 1}px solid ${props => props.color || '#e7e7e7'};
  width: 100%;
  position: absolute;
  top: ${props => props.top || 0};
`;

export const FormLabel = styled.span`
  font-weight: 500;
  color: #000000;
  margin-right: 1em;
`;

export const FormValidation = styled.div`
  color: red;
  line-height: 40px;
  margin-top: -24px;
`;

interface FormItemProps {
  margin?: string;
  width?: string;
  labelmargin?: string;
  isLabelAtTop?: boolean;
  inputWidth?: string;
  labellineheight?: string;
}

export const FormItem = styled(antFormItem)<FormItemProps>`
  &&& {
    position: relative;
    ${props => props.margin && `margin: ${props.margin};`}
    .ant-form-item-control-wrapper {
      width: ${props => (props.width ? props.width : null)};
    }
    .ant-select {
      width: ${props => (props.inputWidth ? props.inputWidth : null)};
    }
    .ant-form-item-label {
      white-space: break-spaces;
      ${props => props.labellineheight && `line-height: ${props.labellineheight};`}
      label {
        color: #000000;
        font-weight: 500;
        ${props => props.labelmargin && `margin: ${props.labelmargin};`}
        ${props => props.isLabelAtTop && `display: block;`}
        &:after {
          opacity: 0;
        }
      }
    }
    &.is-required .ant-form-item-label label:after {
      content: '*';
      color: red;
      opacity: 1;
    }
    .ant-form-item-children {
      display: block;
      // TODO: check this
      //& > div {
      //  padding: 0;
      //}
    }
  }
`;

export const Input = styled(antInput)`
  ${defaultInput};
`;

export const InputNumber = styled(antInputNumber)<{ autoWidth?: boolean }>`
  ${props => props.autoWidth && `width: auto;`}
`;

export const TextArea = styled(antTextArea)`
  ${defaultTextArea};
`;
